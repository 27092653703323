<template>
  <div>
    <page-component
      pageTitle="Modifier Items"
      :restaurantFilterAvailable="true"
      :headers="modItemHeaders"
      :items="filteredModifierItems"
      sortBy="ordinal"
      :buttons="
        userCanEdit
          ? [
              {
                text: ' + Add New Modifier Item',
                to: { params: { form: 'AddEditForm' } },
              },
            ]
          : []
      "
    >
      <template v-slot:filters[0]>
        <custom-select
          v-model="selectedSnoozedStatus"
          :items="snoozedStatusOptions"
          label="Availability"
          multiple
        />
      </template>
      <template v-slot:[`item.modifierListId`]="{ item }">
        {{ getModifierListName(item.modifierListId) }}
        <br />
        <span class="text--lighttext" style="opacity: 0.5">
          {{ item.modifierListId }}
        </span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{
          parseFloat(item.priceMoney.amount / 100).toFixed(2) +
          " " +
          item.priceMoney.currency
        }}
      </template>
      <template v-slot:[`item.isSnoozed`]="{ item }">
        <nxg-snooze-btn
          type="modifier item"
          :item="item"
          show-time
          :show-label="false"
        />
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          v-if="editEnabled(item.restaurantId)"
          type="edit"
          @click="editItem(item)"
        />
        <nxg-action-btn
          v-if="editEnabled(item.restaurantId)"
          type="delete"
          item-type="MODIFIER_ITEM"
          :item="item"
        />
      </template>
    </page-component>
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm' && userCanEdit"
      :menuItems="menuItems"
      @close="
        $router.push({ params: { form: null, id: null } })
        editMode = false
        itemToEdit = {}
      "
    />
  </div>
</template>

<script>
import NxgSnoozeBtn from "../../../shared/SnoozeButton.vue"
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapGetters } from "vuex"
import PageComponent from "../../../shared/PageComponent.vue"
import CustomSelect from "../../../shared/CustomSelect.vue"

export default {
  name: "modifier-items",
  components: {
    NxgSnoozeBtn,
    AddEditForm,
    PageComponent,
    CustomSelect,
  },
  data() {
    return {
      searchTerm: "",
      selectedSnoozedStatus: [true, false],
      modItemHeaders: [
        { text: "Name", value: "name" },
        { text: "Modifier List", value: "modifierListId" },
        { text: "Price", value: "price" },
        { text: "Ordinal", value: "ordinal" },
        { text: "Linked Menu Items", value: "linkedMenuItem" },
        { text: "Available", value: "isSnoozed" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      snoozedStatusOptions: [
        {
          text: "Available",
          value: false,
        },
        {
          text: "Unavailable",
          value: true,
        },
      ],
      itemToEdit: {},
      editMode: false,
      restaurantID: null,
    }
  },
  computed: {
    ...mapGetters(["getModifierListName", "getRestaurantSyncStatus", "userCanEdit"]),
    ...mapState([
      "firebaseRefs",
      "modifierItems",
      "modifierLists",
      "menuItems",
      "restaurants",
    ]),
    filteredModifierItems() {
      let items = this.modifierItems
      return items
        .filter(
          item =>
            item.modifierListId &&
            this.modifierLists.some(list => list.id == item.modifierListId) &&
            this.selectedSnoozedStatus.length &&
            this.selectedSnoozedStatus.includes(item.isSnoozed)
        )
        .map(item => {
          return {
            ...item,
            price: item.priceMoney.amount,
            linkedMenuItem: this.getLinkedMenuItemNames(item),
          }
        })
        .sort((a, b) => (parseFloat(a.ordinal) - parseFloat(b.ordinal) ? -1 : 1))
    },
  },
  methods: {
    editEnabled(restaurantId) {
      return !this.getRestaurantSyncStatus(restaurantId)
    },
    async editItem(item) {
      await this.$store.commit("setItemToEdit", item)
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
    getLinkedMenuItemNames(modifierItem) {
      const linkedMenuItems = this.menuItems.filter(menuItem =>
        menuItem.modifierLists.some(
          list => list.modifierListId == modifierItem.modifierListId
        )
      )
      return linkedMenuItems.length > 0
        ? linkedMenuItems.map(item => item.name).join(", ")
        : "None"
    },
  },
}
</script>
