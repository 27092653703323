var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-component',{attrs:{"pageTitle":"Modifier Items","restaurantFilterAvailable":true,"headers":_vm.modItemHeaders,"items":_vm.filteredModifierItems,"sortBy":"ordinal","buttons":_vm.userCanEdit
        ? [
            {
              text: ' + Add New Modifier Item',
              to: { params: { form: 'AddEditForm' } },
            },
          ]
        : []},scopedSlots:_vm._u([{key:"filters[0]",fn:function(){return [_c('custom-select',{attrs:{"items":_vm.snoozedStatusOptions,"label":"Availability","multiple":""},model:{value:(_vm.selectedSnoozedStatus),callback:function ($$v) {_vm.selectedSnoozedStatus=$$v},expression:"selectedSnoozedStatus"}})]},proxy:true},{key:`item.modifierListId`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getModifierListName(item.modifierListId))+" "),_c('br'),_c('span',{staticClass:"text--lighttext",staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(item.modifierListId)+" ")])]}},{key:`item.price`,fn:function({ item }){return [_vm._v(" "+_vm._s(parseFloat(item.priceMoney.amount / 100).toFixed(2) + " " + item.priceMoney.currency)+" ")]}},{key:`item.isSnoozed`,fn:function({ item }){return [_c('nxg-snooze-btn',{attrs:{"type":"modifier item","item":item,"show-time":"","show-label":false}})]}},(_vm.userCanEdit)?{key:`item.actions`,fn:function({ item }){return [(_vm.editEnabled(item.restaurantId))?_c('nxg-action-btn',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.editItem(item)}}}):_vm._e(),(_vm.editEnabled(item.restaurantId))?_c('nxg-action-btn',{attrs:{"type":"delete","item-type":"MODIFIER_ITEM","item":item}}):_vm._e()]}}:null],null,true)}),(_vm.$route.params.form == 'AddEditForm' && _vm.userCanEdit)?_c('add-edit-form',{attrs:{"menuItems":_vm.menuItems},on:{"close":function($event){_vm.$router.push({ params: { form: null, id: null } })
      _vm.editMode = false
      _vm.itemToEdit = {}}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }